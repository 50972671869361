import {
  ACTION_FETCH_USERS,
  ACTION_FETCH_USER,
  ACTION_FETCH_USERS_PAGING,
} from '@/store/actions.type';
import {
  MUTATION_SET_USERS,
  MUTATION_SET_USER,
  MUTATION_SET_USERS_PAGING,
} from '@/store/mutations.type';

import UserProxy from '@/proxies/user.proxy';

const state = {
  users: [],
  user: {},
  paging: {},
};

const getters = {
  users: (state) => state.users,
  user: (state) => state.user,
  pagingUsers: (state) => state.paging,
};

const actions = {
  [ACTION_FETCH_USERS](state, query) {
    return UserProxy.users(query).then((res) => {
      state.commit(MUTATION_SET_USERS, res.data);
    });
  },
  [ACTION_FETCH_USERS_PAGING](state, query) {
    return UserProxy.usersPaging(query).then((res) => {
      state.commit(MUTATION_SET_USERS_PAGING, res.data);
    });
  },
  [ACTION_FETCH_USER](state, id) {
    return UserProxy.find(id).then((res) => {
      state.commit(MUTATION_SET_USER, res.data);
    });
  },
};

const mutations = {
  [MUTATION_SET_USERS](state, users) {
    state.users = users;
  },
  [MUTATION_SET_USERS_PAGING](state, paging) {
    state.users = paging.rows;
    state.paging = paging;
  },
  [MUTATION_SET_USER](state, user) {
    state.user = user;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
