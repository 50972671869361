import BaseProxy from './base.proxy';

class AnnouncementProxy extends BaseProxy {
  announcements() {
    return this.get('/admin/announcement');
  }
  create(data) {
    return this.post(`/admin/announcement`, data);
  }
}
export default new AnnouncementProxy();
