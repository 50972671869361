import BaseProxy from './base.proxy';

class CategoryProxy extends BaseProxy {
  categories() {
    return this.get('/admin/category');
  }
  category(id) {
    return this.get(`/category/${id}`);
  }
}
export default new CategoryProxy();
