import {
  ACTION_FETCH_CATEGORY,
  ACTION_FETCH_CATEGORIES,
} from '@/store/actions.type';
import {
  MUTATION_SET_CATEGORY,
  MUTATION_SET_CATEGORIES,
} from '@/store/mutations.type';
import CategoryProxy from '@/proxies/category.proxy';

const state = {
  category: {},
  categories: [],
  listCategories: {},
  listSubCategories: {},
};

const getters = {
  category: (state) => state.category,
  categories: (state) => state.categories,
  listCategories: (state) => state.listCategories,
  listSubCategories: (state) => state.listSubCategories,
};

const actions = {
  [ACTION_FETCH_CATEGORIES](state) {
    return CategoryProxy.categories().then((res) => {
      state.commit(MUTATION_SET_CATEGORIES, res.data);
    });
  },
  [ACTION_FETCH_CATEGORY](state, id) {
    return CategoryProxy.category(id).then((res) => {
      state.commit(MUTATION_SET_CATEGORY, res.data);
    });
  },
};

const mutations = {
  [MUTATION_SET_CATEGORY](state, category) {
    state.category = category;
  },
  [MUTATION_SET_CATEGORIES](state, categories) {
    state.categories = categories;

    categories.forEach((category) => {
      state.listCategories[category.id] = category.name;

      //subcategories
      if (category.subcategories) {
        category.subcategories.forEach((sub) => {
          state.listSubCategories[sub.id] = sub.name;
        });
      }
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
