import babelPolyfill from 'babel-polyfill';

import 'core-js/stable';
import Vue from 'vue';
import '@/constants/env';

import utils from '@/utils/utils';
Vue.prototype.utils = utils;

import '@/plugins/vuex';
import '@/plugins/axios';
import '@/plugins/toast';
import '@/plugins/datepicker';
import '@/plugins/image-viewer';
import '@/plugins/tree-select';
import '@/plugins/filter';
import '@/plugins/moment';
import '@/plugins/vue-confirm-dialog';
import '@/plugins/download-csv';

//icons
import { freeSet } from '@coreui/icons';
import { iconsSet } from '@/assets/icons/icons.js';

import router from '@/router';
import App from '@/App';
import CoreuiVue from '@coreui/vue';
import store from '@/store';

import { ACTION_CHECK } from '@/store/actions.type';

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.prototype.$log = console.log.bind(console);

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) =>
  Promise.all([store.dispatch(ACTION_CHECK)]).then(next)
);

new Vue({
  el: '#app',
  router,
  babelPolyfill,
  store,
  icons: { ...freeSet, ...iconsSet },
  template: '<App/>',
  components: {
    App,
  },
});
