<template>
  <router-view></router-view>
</template>

<script>
// import { ACTION_FETCH_PROFILE } from '@/store/actions.type';

export default {
  name: 'App',
  computed: {},
  mounted() {
    if (this.$store.state.auth.authenticated) {
      // this.$store.dispatch(ACTION_FETCH_PROFILE);
    } else {
      this.$router.push({ name: 'login.index' });
    }
  },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';
</style>
