/* ============
 * Axios
 * ============
 *
 * Promise based HTTP client for the browser and node.js.
 * Because Vue Resource has been retired, Axios will now been used
 * to perform AJAX-requests.
 *
 * https://github.com/mzabriskie/axios
 */

import Axios from 'axios';
import store from '@/store';
import env from '@/constants/env';
import Vue from 'vue';
import router from '@/router';

import { ACTION_LOGOUT, ACTION_SET_LOADING } from '@/store/actions.type';

Axios.defaults.baseURL = env.apiBaseUrl;
Axios.defaults.headers.common.Accept = 'application/json';

Axios.interceptors.request.use(
  (config) => {
    //Set loading true
    store.dispatch(ACTION_SET_LOADING, true);
    return config;
  },
  (error) => Promise.reject(error)
);
Axios.interceptors.response.use(
  (response) => {
    // Set loaging false
    store.dispatch(ACTION_SET_LOADING, false);

    return response.data;
  },
  (error) => {
    // Set loaging false
    store.dispatch(ACTION_SET_LOADING, false);

    console.error('Interceptors ERROR:', error.response);

    if (error.response.status >= 400 && error.response.status < 500) {
      Vue.$toast.warning(error.response.data.message);
    } else if (error.response.status >= 500) {
      Vue.$toast.error(error.response.data.message);
    }

    if (error.response.status === 401) {
      store.dispatch(ACTION_LOGOUT);
      router.push({ name: 'login.index' });
    }
    return Promise.reject(error);
  }
);
