//loading
export const MUTATION_SET_LOADING = 'MUTATION_SET_LOADING';

//auth
export const MUTATION_SET_AUTH = 'MUTATION_SET_AUTH';
export const MUTATION_PURGE_AUTH = 'MUTATION_PURGE_AUTH';
export const MUTATION_CHECK_AUTH = 'MUTATION_CHECK_AUTH';

//user
export const MUTATION_SET_USERS = 'MUTATION_SET_USERS';
export const MUTATION_SET_USERS_PAGING = 'MUTATION_SET_USERS_PAGING';
export const MUTATION_SET_USER = 'MUTATION_SET_USER';

//userinternal
export const MUTATION_SET_PROFILE = 'MUTATION_SET_PROFILE';
export const MUTATION_SET_USER_INTERNALS = 'MUTATION_SET_USER_INTERNALS';
export const MUTATION_SET_USER_INTERNAL = 'MUTATION_SET_USER_INTERNAL';

//category
export const MUTATION_SET_CATEGORY = 'MUTATION_SET_CATEGORY';
export const MUTATION_SET_CATEGORIES = 'MUTATION_SET_CATEGORIES';

//provider
export const MUTATION_SET_PROVIDERS = 'MUTATION_SET_PROVIDERS';
export const MUTATION_SET_PROVIDER = 'MUTATION_SET_PROVIDER';
export const MUTATION_SET_DOCUMENTS_OF_PROVIDER =
  'MUTATION_SET_DOCUMENTS_OF_PROVIDER';
export const MUTATION_SET_SERVICES_OF_PROVIDER =
  'MUTATION_SET_SERVICES_OF_PROVIDER';
export const MUTATION_SET_PROVIDER_SETTING = 'MUTATION_SET_PROVIDER_SETTING';
export const MUTATION_SET_PROVIDER_REQUEST = 'MUTATION_SET_PROVIDER_REQUEST';
export const MUTATION_SET_PROVIDER_OWED = 'MUTATION_SET_PROVIDER_OWED';
export const MUTATION_SET_PROVIDER_EARNING_PAYOUT =
  'MUTATION_SET_PROVIDER_EARNING_PAYOUT';

//service
export const MUTATION_SET_SERVICE = 'MUTATION_SET_SERVICE';
export const MUTATION_SET_SERVICES = 'MUTATION_SET_SERVICES';

//booking
export const MUTATION_SET_BOOKING = 'MUTATION_SET_BOOKING';
export const MUTATION_SET_BOOKINGS = 'MUTATION_SET_BOOKINGS';
export const MUTATION_SET_PAGING_BOOKINGS = 'MUTATION_SET_PAGING_BOOKINGS';
export const MUTATION_SET_BOOKING_HISTORIES = 'MUTATION_SET_BOOKING_HISTORIES';
export const MUTATION_SET_BOOKING_QUOTE_HISTORIES =
  'MUTATION_SET_BOOKING_QUOTE_HISTORIES';
export const MUTATION_SET_BOOKING_JOBS = 'MUTATION_SET_BOOKING_JOBS';

//announcement
export const MUTATION_SET_ANNOUNCEMENTS = 'MUTATION_SET_ANNOUNCEMENTS';

//invoice
export const MUTATION_SET_INVOICES = 'MUTATION_SET_INVOICES';
