import BaseProxy from './base.proxy';

class UserProxy extends BaseProxy {
  users(query) {
    let queryString = new URLSearchParams(query).toString();
    return this.get(`/admin/user?${queryString}`);
  }
  usersPaging(query) {
    let queryString = new URLSearchParams(query).toString();
    return this.get(`/admin/user/paging?${queryString}`);
  }
  find(id) {
    return this.get(`/admin/user/${id}`);
  }
  changeStatus(id, data) {
    return this.put(`/admin/user/${id}/status`, data);
  }
  updateUser(id, data) {
    return this.put(`/admin/user/${id}`, data);
  }
}
export default new UserProxy();
