// loading
export const ACTION_SET_LOADING = 'ACTION_SET_LOADING';

// auth
export const ACTION_CHECK = 'ACTION_CHECK';
export const ACTION_LOGIN = 'ACTION_LOGIN';
export const ACTION_LOGOUT = 'ACTION_LOGOUT';

// user
export const ACTION_FETCH_USER = 'ACTION_FETCH_USER';
export const ACTION_FETCH_USERS = 'ACTION_FETCH_USERS';
export const ACTION_FETCH_USERS_PAGING = 'ACTION_FETCH_USERS_PAGING';

// userinternal
export const ACTION_FETCH_PROFILE = 'ACTION_FETCH_PROFILE';
export const ACTION_FETCH_USER_INTERNAL = 'ACTION_FETCH_USER_INTERNAL';
export const ACTION_FETCH_USER_INTERNALS = 'ACTION_FETCH_USER_INTERNALS';

// category
export const ACTION_FETCH_CATEGORY = 'ACTION_FETCH_CATEGORY';
export const ACTION_FETCH_CATEGORIES = 'ACTION_FETCH_CATEGORIES';

// provider
export const ACTION_FETCH_PROVIDER = 'ACTION_FETCH_PROVIDER';
export const ACTION_FETCH_PROVIDERS = 'ACTION_FETCH_PROVIDERS';
export const ACTION_FETCH_DOCUMENTS_OF_PROVIDER =
  'ACTION_FETCH_DOCUMENTS_OF_PROVIDER';
export const ACTION_FETCH_SERVICES_OF_PROVIDER =
  'ACTION_FETCH_SERVICES_OF_PROVIDER';
export const ACTION_FETCH_PROVIDER_SETTING = 'ACTION_FETCH_PROVIDER_SETTING';
export const ACTION_FETCH_PROVIDER_REQUEST = 'ACTION_FETCH_PROVIDER_REQUEST';
export const ACTION_FETCH_PROVIDER_OWED = 'ACTION_FETCH_PROVIDER_OWED';
export const ACTION_FETCH_PROVIDER_EARNING_PAYOUT =
  'ACTION_FETCH_PROVIDER_EARNING_PAYOUT';

// service
export const ACTION_FETCH_SERVICE = 'ACTION_FETCH_SERVICE';
export const ACTION_FETCH_SERVICES = 'ACTION_FETCH_SERVICES';

// booking
export const ACTION_FETCH_BOOKING = 'ACTION_FETCH_BOOKING';
export const ACTION_FETCH_BOOKINGS = 'ACTION_FETCH_BOOKINGS';
export const ACTION_FETCH_PAGING_BOOKINGS = 'ACTION_FETCH_PAGING_BOOKINGS';
export const ACTION_FETCH_BOOKING_HISTORY = 'ACTION_FETCH_BOOKING_HISTORY';
export const ACTION_FETCH_BOOKING_QUOTE_HISTORY =
  'ACTION_FETCH_BOOKING_QUOTE_HISTORY';
export const ACTION_FETCH_BOOKING_JOBS = 'ACTION_FETCH_BOOKING_JOBS';

// category
export const ACTION_FETCH_ANNOUNCEMENTS = 'ACTION_FETCH_ANNOUNCEMENTS';

// invoice
export const ACTION_FETCH_INVOICES = 'ACTION_FETCH_INVOICES';
