import { ACTION_FETCH_INVOICES } from '@/store/actions.type';
import { MUTATION_SET_INVOICES } from '@/store/mutations.type';

import InvoiceProxy from '@/proxies/invoice.proxy';

const state = {
  invoices: [],
};

const getters = {
  invoices: (state) => state.invoices,
};

const actions = {
  [ACTION_FETCH_INVOICES](state, query) {
    return InvoiceProxy.invoices(query).then((res) => {
      state.commit(MUTATION_SET_INVOICES, res.data);
    });
  },
};

const mutations = {
  [MUTATION_SET_INVOICES](state, invoices) {
    state.invoices = invoices;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
