import BaseProxy from './base.proxy';

class UserInternalProxy extends BaseProxy {
  me() {
    return this.get('/admin/me');
  }
  generate2FA() {
    return this.get('/admin/2fa/generate');
  }
  setup2FA(data) {
    return this.post('/admin/2fa/setup', data);
  }
  verify2FA(token, data) {
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    return this.request('post', '/admin/2fa/verify', data, headers);
  }
  users(query) {
    let queryString = new URLSearchParams(query).toString();
    return this.get(`/admin/user-internal?${queryString}`);
  }
  find(id) {
    return this.get(`/admin/user-internal/${id}`);
  }
  changeStatus(id, data) {
    return this.put(`/admin/user-internal/${id}/status`, data);
  }
  addUser(data) {
    return this.post(`/admin/user-internal/`, data);
  }
}
export default new UserInternalProxy();
