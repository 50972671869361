import BaseProxy from './base.proxy';

class AuthProxy extends BaseProxy {
  login({ email, password }) {
    const data = {
      email,
      password,
    };
    return this.post('/admin/login/email', data);
  }
  forgotPassword(email) {
    return this.get(`/admin/reset-password/${email}`);
  }
  changePassword(data) {
    return this.put('/admin/change-password', data);
  }
}
export default new AuthProxy();
