import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

// Containers
const TheContainer = () => import('@/containers/TheContainer');

// Views
const Dashboard = () => import('@/views/Dashboard');
const Profile = () => import('@/views/Profile');
const Setting = () => import('@/views/setting/Setting');

// Views - Pages
const Page404 = () => import('@/views/pages/Page404');
const Page500 = () => import('@/views/pages/Page500');
const Login = () => import('@/views/pages/Login');
const Forgot = () => import('@/views/pages/Forgot');

// Users
const Consumers = () => import('@/views/consumers/Consumers');
const Consumer = () => import('@/views/consumers/Consumer');

// User internal
const UserInternals = () => import('@/views/user-internals/UserInternals');
const UserInternal = () => import('@/views/user-internals/UserInternal');

// Categories
const Categories = () => import('@/views/categories/Categories');
const Category = () => import('@/views/categories/Category');

// Providers
const SpAccounts = () => import('@/views/providers/SP_Accounts');
const SpApplications = () => import('@/views/providers/SP_Applications');
const SpInsurances = () => import('@/views/providers/SP_Insurances');
const SpRequests = () => import('@/views/providers/SP_Requests.vue');
// const Provider = () => import('@/views/providers/Provider');
const Provider = () => import('@/views/providers/SP');

// Services
const Services = () => import('@/views/services/Services');
const Service = () => import('@/views/services/Service');

// Bookings
const Bookings = () => import('@/views/bookings/Bookings');
const Booking = () => import('@/views/bookings/Booking');

// Announcements
const Announcements = () => import('@/views/announcements/Announcements');

// Finances
const Revenue = () => import('@/views/finances/Revenue');
// const SpOwed = () => import('@/views/finances/SpOwed.vue');
const Invoices = () => import('@/views/finances/Invoices.vue');
const SpFeeOwed = () => import('@/views/finances/SpFeeOwed.vue');
const SpBalanceOutstanding = () =>
  import('@/views/finances/SpBalanceOutstanding.vue');

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'home',
      meta: {
        label: 'Home',
      },
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'dashboard.index',
          meta: {
            label: 'Dashboard',
          },
          component: Dashboard,
        },
        {
          path: 'profile',
          name: 'profile.index',
          meta: {
            label: 'Profile',
          },
          component: Profile,
        },
        {
          path: 'setting',
          name: 'setting.index',
          meta: {
            label: 'Settings',
          },
          component: Setting,
        },
        {
          path: 'consumers',
          meta: {
            label: 'Consumers',
          },
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: 'consumers.index',
              meta: {
                label: 'Consumers',
              },
              component: Consumers,
            },
            {
              path: ':id',
              meta: {
                label: 'Consumer',
              },
              name: 'consumer.index',
              component: Consumer,
            },
          ],
        },
        {
          path: 'user-internals',
          meta: {
            label: 'UserInternals',
          },
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: 'user-internals.index',
              meta: {
                label: 'UserInternals',
              },
              component: UserInternals,
            },
            {
              path: ':id',
              meta: {
                label: 'UserInternal',
              },
              name: 'user-internal.index',
              component: UserInternal,
            },
          ],
        },
        {
          path: 'announcements',
          meta: {
            label: 'Announcements',
          },
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: 'announcements.index',
              meta: {
                label: 'Announcements',
              },
              component: Announcements,
            },
            // {
            //   path: ':id',
            //   meta: {
            //     label: 'UserInternal',
            //   },
            //   name: 'user-internal.index',
            //   component: UserInternal,
            // },
          ],
        },
        {
          path: 'categories',
          meta: {
            label: 'Categories',
          },
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: 'categories.index',
              meta: {
                label: 'Categories',
              },
              component: Categories,
            },
            {
              path: ':id',
              meta: {
                label: 'Category Detail',
              },
              name: 'category.i',
              component: Category,
            },
          ],
        },
        {
          path: '/',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: 'sp/accounts',
              name: 'SP Accounts',
              component: SpAccounts,
            },
            {
              path: 'sp/applications',
              name: 'SP Applications',
              component: SpApplications,
            },
            {
              path: 'sp/insurances',
              name: 'SP Insurances',
              component: SpInsurances,
            },
            {
              path: 'sp/request_new_categories',
              name: 'Request New Categories',
              component: SpRequests,
            },

            {
              path: 'sp/:id',
              name: 'SP Detail',
              component: Provider,
            },
          ],
        },
        {
          path: 'services',
          meta: {
            label: 'Services',
          },
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: 'services.index',
              meta: {
                label: 'Services',
              },
              component: Services,
            },
            {
              path: ':id',
              meta: {
                label: 'Services Detail',
              },
              name: 'service.index',
              component: Service,
            },
          ],
        },
        {
          path: 'bookings',
          meta: {
            label: 'Bookings',
          },
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: 'bookings.index',
              meta: {
                label: 'Bookings',
              },
              component: Bookings,
            },
            {
              path: ':id',
              meta: {
                label: 'Booking Detail',
              },
              name: 'booking.index',
              component: Booking,
            },
          ],
        },
        {
          path: '/',
          meta: {
            label: 'Finances',
          },
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '/revenue',
              name: 'Revenue.index',
              meta: {
                label: 'Revenue',
              },
              component: Revenue,
            },
            {
              path: '/sp_balance_outstanding',
              meta: {
                label: 'SPs Balance Outstanding',
              },
              name: 'sp_balance_outstanding.index',
              component: SpBalanceOutstanding,
            },
            {
              path: '/sp_fee_owed',
              meta: {
                label: 'SPs Fee Owed',
              },
              name: 'sp_fee_owed.index',
              component: SpFeeOwed,
            },
            {
              path: '/invoices',
              meta: {
                label: 'Invoices',
              },
              name: 'invoices.index',
              component: Invoices,
            },
          ],
        },
      ],
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '404',
          name: 'page404.index',
          component: Page404,
        },
        {
          path: '500',
          name: 'page500.index',
          component: Page500,
        },
        {
          path: 'login',
          name: 'login.index',
          component: Login,
        },
        {
          path: 'forgot',
          name: 'forgot.index',
          component: Forgot,
        },
      ],
    },
    { path: '/:pathMatch(.*)*', component: Page404 },
  ],
});
