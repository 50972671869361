import Vuex from 'vuex';
import loading from './modules/loading.module';
import sidebar from './modules/sidebar.module';
import auth from './modules/auth.module';
import user from './modules/user.module';
import userinternal from './modules/userinternal.module';
import category from './modules/category.module';
import provider from './modules/provider.module';
import service from './modules/service.module';
import booking from './modules/booking.module';
import announcement from './modules/announcement.module';
import invoice from './modules/invoice.module';

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    loading,
    sidebar,
    auth,
    user,
    userinternal,
    category,
    provider,
    service,
    booking,
    announcement,
    invoice,
  },

  /**
   * If strict mode should be enabled.
   */
  strict: debug,
});
