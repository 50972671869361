import { ACTION_FETCH_ANNOUNCEMENTS } from '@/store/actions.type';
import { MUTATION_SET_ANNOUNCEMENTS } from '@/store/mutations.type';
import AnnouncementProxy from '@/proxies/announcement.proxy';

const state = {
  announcements: [],
};

const getters = {
  announcements: (state) => state.announcements,
};

const actions = {
  [ACTION_FETCH_ANNOUNCEMENTS](state) {
    return AnnouncementProxy.announcements().then((res) => {
      state.commit(MUTATION_SET_ANNOUNCEMENTS, res.data);
    });
  },
};

const mutations = {
  [MUTATION_SET_ANNOUNCEMENTS](state, announcements) {
    state.announcements = announcements;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
