import {
  ACTION_FETCH_PROFILE,
  ACTION_FETCH_USER_INTERNALS,
  ACTION_FETCH_USER_INTERNAL,
} from '@/store/actions.type';
import {
  MUTATION_SET_PROFILE,
  MUTATION_SET_USER_INTERNALS,
  MUTATION_SET_USER_INTERNAL,
} from '@/store/mutations.type';

import UserInternalProxy from '@/proxies/userinternal.proxy';

const state = {
  profile: {},
  userinternals: [],
  userinternal: {},
};

const getters = {
  profile: (state) => state.profile,
  permissions: (state) => state.profile.permissions || [],
  userinternals: (state) => state.userinternals,
  userinternal: (state) => state.userinternal,
};

const actions = {
  [ACTION_FETCH_PROFILE](state) {
    return UserInternalProxy.me().then((res) => {
      state.commit(MUTATION_SET_PROFILE, res.data);
      return res.data;
    });
  },
  [ACTION_FETCH_USER_INTERNALS](state, { status, email }) {
    let query = {};
    if (status && status.length > 0) {
      query.status = status;
    }
    if (email && email.length > 0) {
      query.email = email;
    }
    return UserInternalProxy.users(query).then((res) => {
      state.commit(MUTATION_SET_USER_INTERNALS, res.data);
    });
  },
  [ACTION_FETCH_USER_INTERNAL](state, id) {
    return UserInternalProxy.find(id).then((res) => {
      state.commit(MUTATION_SET_USER_INTERNAL, res.data);
    });
  },
};

const mutations = {
  [MUTATION_SET_PROFILE](state, profile) {
    state.profile = profile;
  },
  [MUTATION_SET_USER_INTERNALS](state, userinternals) {
    state.userinternals = userinternals;
  },
  [MUTATION_SET_USER_INTERNAL](state, userinternal) {
    state.userinternal = userinternal;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
