import { ACTION_SET_LOADING } from '@/store/actions.type';
import { MUTATION_SET_LOADING } from '@/store/mutations.type';

const state = {
  loading: false,
};

const getters = {
  loading: (state) => state.loading,
};

const actions = {
  [ACTION_SET_LOADING](state, loading) {
    state.commit(MUTATION_SET_LOADING, loading);
  },
};

const mutations = {
  [MUTATION_SET_LOADING](state, loading) {
    state.loading = loading;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
