import {
  ACTION_FETCH_SERVICES,
  ACTION_FETCH_SERVICE,
} from '@/store/actions.type';
import {
  MUTATION_SET_SERVICE,
  MUTATION_SET_SERVICES,
} from '@/store/mutations.type';
import ServiceProxy from '@/proxies/service.proxy';

const state = {
  service: {},
  services: [],
};

const getters = {
  services: (state) => state.services,
  service: (state) => state.service,
};

const actions = {
  [ACTION_FETCH_SERVICES](state) {
    return ServiceProxy.services().then((res) => {
      state.commit(MUTATION_SET_SERVICES, res.data);
    });
  },
  [ACTION_FETCH_SERVICE](state, id) {
    return ServiceProxy.service(id).then((res) => {
      state.commit(MUTATION_SET_SERVICE, res.data);
    });
  },
};

const mutations = {
  [MUTATION_SET_SERVICE](state, service) {
    state.service = service;
  },
  [MUTATION_SET_SERVICES](state, services) {
    state.services = services;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
