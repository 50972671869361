import axios from 'axios';
import env from '@/constants/env';
import { CompressImageFromFile } from '@/utils/utils';
class BaseProxy {
  constructor() {}
  defaultHeaders() {
    const access_token = localStorage.getItem('id_token');
    let defaultHeaders = { 'Content-Type': 'application/json' };
    if (access_token) {
      defaultHeaders = {
        ...defaultHeaders,
        Authorization: `Bearer ${access_token}`,
      };
    }
    return defaultHeaders;
  }
  submit(method, url, data = null) {
    console.debug(`${method}: ${url}`);
    let headers = this.defaultHeaders();

    return axios({
      method: method,
      url: `${env.apiBaseUrl}${url}`,
      data: data,
      headers: headers,
    }).then((response) => {
      console.debug(`RESPONSE: ${url}`, response);
      return Promise.resolve(response);
    });
  }

  request(method, url, data = null, headers = {}) {
    console.debug(`${method}: ${url}`);
    return axios({
      method: method,
      url: `${env.apiBaseUrl}${url}`,
      data: data,
      headers: headers,
    }).then((response) => {
      console.debug(`RESPONSE: ${url}`, response);
      return Promise.resolve(response);
    });
  }

  async upload(file, url) {
    console.debug('upload', file, url);
    let fileUpload = file;
    if (
      file.type === 'image/png' ||
      file.type === 'image/jpg' ||
      file.type === 'image/jpeg'
    ) {
      let compressed = await CompressImageFromFile(file, {
        scale: 80,
        quality: 80,
      });
      fileUpload = compressed.file;
    }

    let formData = new FormData();
    formData.append('file', fileUpload);

    return axios
      .put(url, fileUpload, {
        headers: {
          'x-ms-blob-type': 'BlockBlob',
          'x-ms-blob-content-type': fileUpload.type,
          'Content-Type': fileUpload.type,
        },
        onUploadProgress: function (progressEvent) {
          let uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
          console.debug('upload process', uploadPercentage);
        }.bind(this),
      })
      .then((response) => {
        console.debug(`RESPONSE: ${url}`, response);
        return Promise.resolve(response);
      });
  }

  get(url) {
    return this.submit('get', url);
  }
  delete(url) {
    return this.submit('delete', url);
  }
  put(url, data) {
    return this.submit('put', url, data);
  }
  post(url, data) {
    return this.submit('post', url, data);
  }
}

export default BaseProxy;
