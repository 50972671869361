import BaseProxy from './base.proxy';

class ServiceProxy extends BaseProxy {
  services() {
    return this.get('/post-service');
  }
  service(id) {
    return this.get(`/post-service/${id}`);
  }
}
export default new ServiceProxy();
