import BaseProxy from './base.proxy';

class BookingProxy extends BaseProxy {
  bookings(query) {
    let queryString = new URLSearchParams(query).toString();
    return this.get(`/admin/booking?${queryString}`);
  }
  getPagingBookings(query) {
    let queryString = new URLSearchParams(query).toString();
    return this.get(`/admin/booking/paging?${queryString}`);
  }
  booking(id) {
    return this.get(`/booking/${id}`);
  }
  bookingHistory(query) {
    let queryString = new URLSearchParams(query).toString();
    return this.get(`/admin/booking/history?${queryString}`);
  }
  bookingQuoteHistory(booking_id) {
    return this.get(`/admin/booking/${booking_id}/quote/history`);
  }
  getListJobOfBooking(id) {
    let queryString = new URLSearchParams({ booking_id: id }).toString();
    return this.get(`/admin/job?${queryString}`);
  }
  changeStatusWithAction(id, action, data) {
    return this.put(`/admin/booking/${id}/${action}`, data);
  }
  changeJobStatusWithAction(id, action, data) {
    return this.put(`/admin/job/${id}/${action}`, data);
  }
  adminApproveLateCancellation(id) {
    return this.put(`/admin/booking/${id}/approve/late-cancellation`, {});
  }
  adminRejectLateCancellation(id) {
    return this.put(`/admin/booking/${id}/reject/late-cancellation`, {});
  }
  adminGetTaskQueueBooking(id) {
    return this.get(`/admin/booking/${id}/task-queue`, {});
  }
  adminNotificationOfBooking(id) {
    let queryString = new URLSearchParams({ booking_id: id }).toString();
    return this.get(`/admin/notification?${queryString}`);
  }
  adminAssignToProvider(id, data) {
    return this.post(`/admin/booking/${id}/assign-to-provider`, data);
  }
}
export default new BookingProxy();
