import {
  ACTION_CHECK,
  ACTION_LOGIN,
  ACTION_LOGOUT,
} from '@/store/actions.type';
import {
  MUTATION_CHECK_AUTH,
  MUTATION_SET_AUTH,
  MUTATION_PURGE_AUTH,
} from '@/store/mutations.type';

import AuthProxy from '@/proxies/auth.proxy';

const state = {
  authenticated: false,
  accessToken: null,
};

const getters = {
  accessToken: (state) => state.accessToken,
};

const actions = {
  [ACTION_CHECK](state) {
    state.commit(MUTATION_CHECK_AUTH);
  },
  [ACTION_LOGIN](state, credentials) {
    return AuthProxy.login(credentials).then((res) => {
      let TwoFactorEnabled = res.data.two_factor_enabled;
      if (!TwoFactorEnabled) {
        const token = res.data.token;
        state.commit(MUTATION_SET_AUTH, token);
      }
      return res.data;
    });
  },
  [ACTION_LOGOUT](state) {
    state.commit(MUTATION_PURGE_AUTH);
  },
};

const mutations = {
  [MUTATION_CHECK_AUTH](state) {
    state.authenticated = !!localStorage.getItem('id_token');
    if (state.authenticated) {
      state.accessToken = localStorage.getItem('id_token');
    }
  },
  [MUTATION_SET_AUTH](state, token) {
    state.authenticated = true;
    localStorage.setItem('id_token', token);
    state.accessToken = token;

    // store.dispatch(ACTION_FETCH_PROFILE);
  },
  [MUTATION_PURGE_AUTH](state) {
    state.authenticated = false;
    localStorage.removeItem('id_token');
    state.accessToken = null;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
