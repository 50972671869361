import Vue from 'vue';

import moment from 'moment';
Vue.prototype.moment = moment;
import VueMoment from 'vue-moment';
Vue.use(VueMoment);

/*
Fixed: Could not build a valid `moment` object from input.
Ref: https://github.com/brockpetrie/vue-moment/issues/75#issuecomment-686867339
*/
Vue.filter('moment', function (value, format) {
  if (
    value === null ||
    value === undefined ||
    value == '' ||
    format === undefined
  ) {
    return '';
  }
  if (format === 'from') {
    return moment(value).fromNow();
  }
  return moment(value).format(format);
});
