import {
  ACTION_FETCH_BOOKINGS,
  ACTION_FETCH_PAGING_BOOKINGS,
  ACTION_FETCH_BOOKING,
  ACTION_FETCH_BOOKING_HISTORY,
  ACTION_FETCH_BOOKING_QUOTE_HISTORY,
  ACTION_FETCH_BOOKING_JOBS,
} from '@/store/actions.type';
import {
  MUTATION_SET_BOOKING,
  MUTATION_SET_BOOKINGS,
  MUTATION_SET_PAGING_BOOKINGS,
  MUTATION_SET_BOOKING_HISTORIES,
  MUTATION_SET_BOOKING_QUOTE_HISTORIES,
  MUTATION_SET_BOOKING_JOBS,
} from '@/store/mutations.type';
import BookingProxy from '@/proxies/booking.proxy';

const state = {
  bookings: [],
  booking: {},
  histories: [],
  quote_histories: [],
  jobs: [],
  paging: {},
};

const getters = {
  bookings: (state) => state.bookings,
  booking: (state) => state.booking,
  histories: (state) => state.histories,
  quote_histories: (state) => state.quote_histories,
  jobs: (state) => state.jobs,
  pagingBookings: (state) => state.paging,
};

const actions = {
  [ACTION_FETCH_PAGING_BOOKINGS](state, query) {
    return BookingProxy.getPagingBookings(query).then((res) => {
      state.commit(MUTATION_SET_PAGING_BOOKINGS, res.data);
    });
  },
  [ACTION_FETCH_BOOKINGS](state, query) {
    return BookingProxy.getBookings(query).then((res) => {
      state.commit(MUTATION_SET_BOOKINGS, res.data);
    });
  },
  [ACTION_FETCH_BOOKING](state, id) {
    return BookingProxy.booking(id).then((res) => {
      state.commit(MUTATION_SET_BOOKING, res.data);
    });
  },
  [ACTION_FETCH_BOOKING_HISTORY](state, query) {
    return BookingProxy.bookingHistory(query).then((res) => {
      state.commit(MUTATION_SET_BOOKING_HISTORIES, res.data);
    });
  },
  [ACTION_FETCH_BOOKING_QUOTE_HISTORY](state, booking_id) {
    return BookingProxy.bookingQuoteHistory(booking_id).then((res) => {
      state.commit(MUTATION_SET_BOOKING_QUOTE_HISTORIES, res.data);
    });
  },
  [ACTION_FETCH_BOOKING_JOBS](state, id) {
    return BookingProxy.getListJobOfBooking(id).then((res) => {
      state.commit(MUTATION_SET_BOOKING_JOBS, res.data);
    });
  },
};

const mutations = {
  [MUTATION_SET_BOOKING](state, booking) {
    state.booking = booking;
  },
  [MUTATION_SET_BOOKINGS](state, bookings) {
    state.bookings = bookings;
  },
  [MUTATION_SET_PAGING_BOOKINGS](state, paging) {
    state.bookings = paging.rows;
    state.paging = paging;
  },
  [MUTATION_SET_BOOKING_HISTORIES](state, histories) {
    state.histories = histories;
  },
  [MUTATION_SET_BOOKING_QUOTE_HISTORIES](state, histories) {
    state.quote_histories = histories;
  },
  [MUTATION_SET_BOOKING_JOBS](state, jobs) {
    state.jobs = jobs;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
