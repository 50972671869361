import Vue from 'vue';

Vue.filter('currency', (value, currency = 'USD') => {
  if (typeof value !== 'number') {
    return value;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
});

Vue.filter('currency100', (value, currency = 'USD') => {
  if (typeof value !== 'number') {
    return value;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });
  return formatter.format(value / 100);
});

Vue.filter('percent100', (value) => {
  if (typeof value !== 'number') {
    return value;
  }
  return `${value}%`;
});
