import {
  ACTION_FETCH_PROVIDERS,
  ACTION_FETCH_PROVIDER,
  ACTION_FETCH_DOCUMENTS_OF_PROVIDER,
  ACTION_FETCH_SERVICES_OF_PROVIDER,
  ACTION_FETCH_PROVIDER_SETTING,
  ACTION_FETCH_PROVIDER_REQUEST,
  ACTION_FETCH_PROVIDER_OWED,
  ACTION_FETCH_PROVIDER_EARNING_PAYOUT,
} from '@/store/actions.type';

import {
  MUTATION_SET_PROVIDER,
  MUTATION_SET_PROVIDERS,
  MUTATION_SET_DOCUMENTS_OF_PROVIDER,
  MUTATION_SET_SERVICES_OF_PROVIDER,
  MUTATION_SET_PROVIDER_SETTING,
  MUTATION_SET_PROVIDER_REQUEST,
  MUTATION_SET_PROVIDER_OWED,
  MUTATION_SET_PROVIDER_EARNING_PAYOUT,
} from '@/store/mutations.type';

import ProviderProxy from '@/proxies/provider.proxy';

const state = {
  providers: [],
  provider: {},
  documents: [],
  services: [],
  setting: {},
  requestProviders: [],
  providerOweds: [],
  earningPayouts: [],
};

const getters = {
  providers: (state) => state.providers,
  provider: (state) => state.provider,
  documentsOfProvider: (state) => state.documents,
  servicesOfProvider: (state) => state.services,
  providerSetting: (state) => state.setting,
  requestProviders: (state) => state.requestProviders,
  providerOweds: (state) => state.providerOweds,
  earningPayouts: (state) => state.earningPayouts,
};

const actions = {
  [ACTION_FETCH_PROVIDERS](state, query) {
    return ProviderProxy.providers(query).then((res) => {
      state.commit(MUTATION_SET_PROVIDERS, res.data);
    });
  },
  [ACTION_FETCH_PROVIDER](state, id) {
    return ProviderProxy.provider(id).then((res) => {
      state.commit(MUTATION_SET_PROVIDER, res.data);
    });
  },
  [ACTION_FETCH_PROVIDER_SETTING](state, id) {
    return ProviderProxy.providerSetting(id).then((res) => {
      state.commit(MUTATION_SET_PROVIDER_SETTING, res.data);
    });
  },
  [ACTION_FETCH_DOCUMENTS_OF_PROVIDER](state, id) {
    return ProviderProxy.getDocuments(id).then((res) => {
      state.commit(MUTATION_SET_DOCUMENTS_OF_PROVIDER, res.data);
    });
  },
  [ACTION_FETCH_SERVICES_OF_PROVIDER](state, id) {
    return ProviderProxy.getServices(id).then((res) => {
      state.commit(MUTATION_SET_SERVICES_OF_PROVIDER, res.data);
    });
  },
  [ACTION_FETCH_PROVIDER_REQUEST](state, query) {
    return ProviderProxy.getRequests(query).then((res) => {
      state.commit(MUTATION_SET_PROVIDER_REQUEST, res.data);
    });
  },
  [ACTION_FETCH_PROVIDER_OWED](state, query) {
    return ProviderProxy.getProviderOwed(query).then((res) => {
      state.commit(MUTATION_SET_PROVIDER_OWED, res.data);
    });
  },
  [ACTION_FETCH_PROVIDER_EARNING_PAYOUT](state, id) {
    return ProviderProxy.getEarningPayouts(id).then((res) => {
      state.commit(MUTATION_SET_PROVIDER_EARNING_PAYOUT, res.data);
    });
  },
};

const mutations = {
  [MUTATION_SET_PROVIDERS](state, providers) {
    state.providers = providers;
  },
  [MUTATION_SET_PROVIDER](state, provider) {
    state.provider = provider;
  },
  [MUTATION_SET_PROVIDER_SETTING](state, setting) {
    state.setting = setting;
  },
  [MUTATION_SET_DOCUMENTS_OF_PROVIDER](state, documents) {
    state.documents = documents;
  },
  [MUTATION_SET_SERVICES_OF_PROVIDER](state, services) {
    state.services = services;
  },
  [MUTATION_SET_PROVIDER_REQUEST](state, providers) {
    state.requestProviders = providers;
  },
  [MUTATION_SET_PROVIDER_OWED](state, providerOweds) {
    state.providerOweds = providerOweds;
  },
  [MUTATION_SET_PROVIDER_EARNING_PAYOUT](state, earningPayouts) {
    state.earningPayouts = earningPayouts;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
